import * as React from "react";
import * as style from "./Button.style";
import { Spinner } from "../Icon";
import { classes } from "typestyle";

interface ComponentProps {
  className?: string;
  label: string;
  disabled?: boolean;
  inProgress?: boolean;
  onClick: () => void;
  inverse?: boolean;
}

export const Button: React.FC<ComponentProps> = ({
  className,
  label,
  disabled,
  onClick,
  inProgress,
  inverse,
}) => {
  return (
    <div
      className={classes(
        className,
        !inverse ? style.component : style.componentInverse,
        disabled ? style.disabled : "",
      )}
      onClick={!disabled ? onClick : undefined}
    >
      {inProgress ? <Spinner size={16} /> : label}
    </div>
  );
};
