import { gql } from "@apollo/client";

export const CREATE_QUEST_MUTATION = gql`
  mutation adminCreateQuest(
    $name: String!
    $description: String
    $questType: QuestType!
    $tier: Int!
    $requiredActionCount: Int!
    $rewardAmount: Int!
  ) {
    adminCreateQuest(
      name: $name
      description: $description
      questType: $questType
      tier: $tier
      requiredActionCount: $requiredActionCount
      rewardAmount: $rewardAmount
    ) {
      id
      createdAtUtc
      updatedAtUtc
      adminUpdatedBy
      name
      description
      questType
      tier
      requiredActionCount
      rewardAmount
      status
    }
  }
`;
