import React from "react";
import * as style from "./UpdateTemplate.style";
import * as Components from "../../../../components";
import * as Models from "../../../../models";
import { Checkbox, MenuItem, Select } from "@mui/material";
import { useApolloClient, useMutation } from "@apollo/client";
import { Mutation, Query } from "../../../../gql";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  ContestActions,
  RewardActions,
  UserActions,
} from "../../../../store/actions";
import { RootState } from "../../../../store/reducers";
import { connect } from "react-redux";
import { RewardsStoreBrand } from "../../../../store/reducers/rewards";
import { ContestTemplateStatus, Sport } from "../../../../models/contest";

interface ComponentProps {
  contestTemplateId: number;
  onSuccess: () => void;
}

interface StateProps {
  rewards: Record<number, Models.Rewards.Reward> | null;
  brands: Record<number, RewardsStoreBrand> | null;
  contestTemplate: Models.Contests.ContestTemplate;
}

export const UpdateTemplateInternal: React.FC<StateProps & ComponentProps> = ({
  rewards,
  brands,
  contestTemplateId,
  contestTemplate,
  onSuccess,
}) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  /**
   * Values
   */
  const [status, setStatus] =
    React.useState<Models.Contests.ContestTemplateStatus>(
      contestTemplate.status,
    );
  const [name, setName] = React.useState(contestTemplate.name);
  const [description, setDescription] = React.useState(
    contestTemplate.description,
  );
  const [sport, setSport] = React.useState(contestTemplate.sport ?? "");
  const [viewableStartsAtUtc, setViewableStartsAtUtc] =
    React.useState<Date | null>(contestTemplate.viewableStartsAtUtc);
  const [availableStartsAtUtc, setAvailableStartsAtUtc] =
    React.useState<Date | null>(contestTemplate.availableStartsAtUtc);
  const [availableEndsAtUtc, setAvailableEndsAtUtc] =
    React.useState<Date | null>(contestTemplate.availableEndsAtUtc);
  // const [minContestants, setMinContestants] = React.useState("");
  // const [maxContestants, setMaxContestants] = React.useState("");
  const [format1v1, setFormat1v1] = React.useState(
    contestTemplate.minContestants < 3 ? true : false,
  );
  const [formatGroup, setFormatGroup] = React.useState(
    contestTemplate.maxContestants > 2 ? true : false,
  );
  const [maxContests, setMaxContests] = React.useState(
    contestTemplate.maxContests?.toString() ?? "",
  );
  const [tokenEntry, setTokenEntry] = React.useState(
    contestTemplate.tokenEntry,
  );
  const [freeEntry, setFreeEntry] = React.useState(contestTemplate.freeEntry);
  const [minTokenEntry, setMinTokenEntry] = React.useState(
    contestTemplate.minTokenEntry?.toString() ?? "",
  );
  const [tokenRewardMultiplier, setTokenRewardMultiplier] = React.useState<
    number | undefined
  >(contestTemplate.tokenRewardMultiplier ?? undefined);
  // const [maxTokenEntry, setMaxTokenEntry] = React.useState("");
  const [rewardId, setRewardId] = React.useState(
    contestTemplate.rewardId?.toString() ?? "",
  );
  const [sponsorId, setSponsorId] = React.useState(
    contestTemplate.sponsorId?.toString() ?? "",
  );
  /**
   * Error Values
   */
  const [submitError, setSubmitError] = React.useState("");
  const [nameError, setNameError] = React.useState("");
  const [descriptionError, setDescriptionError] = React.useState("");
  const [viewableStartsAtUtcError, setViewableStartsAtUtcError] =
    React.useState("");
  const [availableStartsAtUtcError, setAvailableStartsAtUtcError] =
    React.useState("");
  const [availableEndsAtUtcError, setAvailableEndsAtUtcError] =
    React.useState("");
  // const [minContestantsError, setMinContestantsError] = React.useState("");
  // const [maxContestantsError, setMaxContestantsError] = React.useState("");
  const [maxContestsError, setMaxContestsError] = React.useState("");
  const [minTokenEntryError, setMinTokenEntryError] = React.useState("");
  // const [maxTokenEntryError, setMaxTokenEntryError] = React.useState("");
  const [rewardIdError, setRewardIdError] = React.useState("");

  /**
   * State
   */
  const [inProgress, setInProgress] = React.useState(false);
  /**
   * Mutation
   */
  const [updateContestTemplate, updateContestTemplateStatus] = useMutation(
    Mutation.UPDATE_CONTEST_TEMPLATE_MUTATION,
  );

  const rewardsArray = React.useMemo(() => {
    return !!rewards ? Object.values(rewards) : [];
  }, [rewards]);
  const brandsArray = React.useMemo(() => {
    return !!brands ? Object.values(brands) : [];
  }, [brands]);
  const getRewards = (skip?: number) => {
    client
      .query({
        query: Query.GET_REWARDS_QUERY,
        variables: {
          skip: skip ?? 0,
          take: 20,
        },
      })
      .then((res) => {
        dispatch(RewardActions.GetRewardsSucces(res.data.adminGetRewards));
      });
  };
  const getBrands = (skip?: number) => {
    client
      .query({
        query: Query.GET_BRANDS_QUERY,
        variables: {
          skip: skip ?? 0,
          take: 20,
        },
      })
      .then((res) => {
        dispatch(RewardActions.GetBrandsSuccess(res.data.adminGetBrands));
      });
  };
  React.useEffect(() => {
    getRewards();
    getBrands();
  }, []);

  /**
   * Validation and Submission
   */
  const onSubmit = () => {
    setSubmitError("");
    setNameError("");
    setDescriptionError("");
    setRewardIdError("");
    setMaxContestsError("");
    setViewableStartsAtUtcError("");
    setAvailableEndsAtUtcError("");
    setAvailableStartsAtUtcError("");
    setMinTokenEntryError("");
    if (!tokenEntry && !freeEntry) {
      setSubmitError(
        "Contest Template must enable token entry, free entry or both",
      );
      return;
    } else if (!!tokenEntry && !minTokenEntry) {
      setMinTokenEntryError(
        "Token Entry contests must include a value for Min Token Entry",
      );
      return;
      // } else if (!!tokenEntry && !maxTokenEntry) {
      //   setMaxTokenEntryError(
      //     "Token Entry contests must include a value for Max Token Entry",
      //   );
      //   return;
    } else if (!!tokenEntry && !rewardId) {
      setRewardIdError("Token Entry contests must include a Reward");
      return;
      // } else if (!!tokenEntry && minTokenEntry > maxTokenEntry) {
      //   setMinTokenEntryError(
      //     "Min Token Entry must be less than max token entry",
      //   );
      //   return;
    } else if (
      !viewableStartsAtUtc ||
      !availableStartsAtUtc ||
      !availableEndsAtUtc
    ) {
      setViewableStartsAtUtcError(
        !viewableStartsAtUtc ? "Must set a date for viewable start time" : "",
      );
      setAvailableStartsAtUtcError(
        !availableStartsAtUtc ? "Must set a date for available start time" : "",
      );
      setAvailableEndsAtUtcError(
        !availableEndsAtUtc ? "Must set a date for available end time" : "",
      );
      return;
    } else if (availableEndsAtUtc < availableStartsAtUtc) {
      setAvailableEndsAtUtcError("Available end time must be after start time");
      return;
      // } else if (!minContestants || Number.parseInt(minContestants) < 1) {
      //   setMinContestantsError("Min Contestants must be at least 1");
      //   return;
      // } else if (!maxContestants || Number.parseInt(maxContestants) < 1) {
      //   setMaxContestantsError("Max Contestants must be at least 1");
      //   return;
      // }
    } else if (!format1v1 && !formatGroup) {
      setSubmitError("Must allow 1v1 contests, Group contests, or both");
      return;
    }
    setInProgress(true);
    updateContestTemplate({
      variables: {
        id: contestTemplateId,
        name,
        status,
        description,
        viewableStartsAtUtc,
        availableStartsAtUtc,
        availableEndsAtUtc,
        minContestants: !!format1v1 ? 2 : 3,
        maxContestants: !!formatGroup ? 8 : 2,
        maxContests: Number.parseInt(maxContests),
        tokenEntry,
        tokenRewardMultiplier,
        rewardId:
          !!tokenEntry && !!rewardId ? Number.parseInt(rewardId) : undefined,
        freeEntry,
        // TODO: reimplement maxTokenEntry
        minTokenEntry: !!minTokenEntry
          ? Number.parseInt(minTokenEntry)
          : undefined,
        maxTokenEntry: !!minTokenEntry
          ? Number.parseInt(minTokenEntry)
          : undefined,
      },
    })
      .then((res) => {
        setInProgress(false);
        toast.success("Success!");
        if (res.data.adminUpdateContestTemplate) {
          dispatch(
            ContestActions.getContestTemplatesSuccess({
              contestTemplates: [res.data.adminUpdateContestTemplate],
            }),
          );
          onSuccess();
        }
      })
      .catch((e) => {
        toast.error(JSON.parse(JSON.stringify(e)).message);
        setInProgress(false);
        console.log("e", e);
      });
  };

  React.useEffect(() => {
    setName(contestTemplate.name);
    setDescription(contestTemplate.description);
    setViewableStartsAtUtc(new Date(contestTemplate.viewableStartsAtUtc));
    setAvailableStartsAtUtc(new Date(contestTemplate.availableStartsAtUtc));
    setAvailableEndsAtUtc(new Date(contestTemplate.availableEndsAtUtc));
    setFormat1v1(contestTemplate.minContestants < 3);
    setFormatGroup(contestTemplate.maxContestants > 2);
    setMaxContests(contestTemplate.maxContests?.toString() ?? "");
    setTokenEntry(contestTemplate.tokenEntry);
    setFreeEntry(contestTemplate.freeEntry);
    setMinTokenEntry(contestTemplate.minTokenEntry?.toString() ?? "");
    setRewardId(contestTemplate.rewardId?.toString() ?? "");
    setSponsorId(contestTemplate.sponsorId?.toString() ?? "");
    setSport(contestTemplate.sport?.toString() ?? "");
    setTokenRewardMultiplier(
      contestTemplate.tokenRewardMultiplier ?? undefined,
    );
    setStatus(contestTemplate.status);
  }, [contestTemplateId]);

  return (
    <div className={style.main}>
      <div>
        <Components.DateTimePicker
          wrapperClassName={style.input}
          className={style.picker}
          value={viewableStartsAtUtc}
          onChange={(date) => setViewableStartsAtUtc(date)}
          label="Contest Template is Viewable Starting at"
          error={viewableStartsAtUtcError}
        />
        <Components.DateTimePicker
          wrapperClassName={style.input}
          className={style.picker}
          value={availableStartsAtUtc}
          onChange={(date) => setAvailableStartsAtUtc(date)}
          label="Contest Template is Available Starting at"
          error={availableStartsAtUtcError}
        />
        <Components.DateTimePicker
          wrapperClassName={style.input}
          className={style.picker}
          value={availableEndsAtUtc}
          onChange={(date) => setAvailableEndsAtUtc(date)}
          label="Contest Template is Available Ending at"
          error={availableEndsAtUtcError}
        />
        <div
          style={{
            marginBottom: 12,
            display: "flex",
            gap: 12,
            alignItems: "center",
          }}
        >
          <Select
            multiple={false}
            value={status}
            onChange={(e) => setStatus(e.target.value as ContestTemplateStatus)}
            autoWidth={true}
            label="Sponsor"
          >
            <MenuItem value={ContestTemplateStatus.DRAFT}>
              {ContestTemplateStatus.DRAFT}
            </MenuItem>
            <MenuItem value={ContestTemplateStatus.PUBLISHED}>
              {ContestTemplateStatus.PUBLISHED}
            </MenuItem>
            <MenuItem value={ContestTemplateStatus.DISABLED}>
              {ContestTemplateStatus.DISABLED}
            </MenuItem>
          </Select>
          <div>Status</div>
        </div>
        <Components.TextInput
          className={style.textInput}
          value={name}
          onChange={setName}
          label="Name"
          error={nameError}
          autocomplete="off"
        />
        <Components.TextInput
          className={style.textInput}
          value={description}
          onChange={setDescription}
          label="Description"
          error={descriptionError}
        />
        {/* <Components.TextInput
          className={style.textInput}
          value={minContestants}
          onChange={setMinContestants}
          label="Min Contestants"
          inputType="number"
          error={minContestantsError}
        />
        <Components.TextInput
          className={style.textInput}
          value={maxContestants}
          onChange={setMaxContestants}
          label="Max Contestants"
          inputType="number"
          error={maxContestantsError}
        /> */}
        <Components.TextInput
          className={style.textInput}
          value={maxContests}
          onChange={setMaxContests}
          label="Max Contests"
          inputType="number"
          error={maxContestsError}
        />
        <div
          style={{
            marginBottom: 12,
            display: "flex",
            gap: 12,
            alignItems: "center",
          }}
        >
          <Select
            multiple={false}
            value={sponsorId}
            onChange={(e) => setSponsorId(e.target.value as string)}
            autoWidth={true}
            label="Sponsor"
          >
            {brandsArray.map((brand) => (
              <MenuItem value={brand.id} key={brand.id}>
                {brand.name}
              </MenuItem>
            ))}
          </Select>
          <div>Sponsor (Optional)</div>
          <div
            onClick={() => setSponsorId("")}
            style={{
              padding: 8,
              backgroundColor: "lightblue",
              cursor: "pointer",
            }}
          >
            Clear
          </div>
        </div>
        <div
          style={{
            marginBottom: 12,
            display: "flex",
            gap: 12,
            alignItems: "center",
          }}
        >
          <Select
            multiple={false}
            value={sport}
            onChange={(e) => setSport(e.target.value as string)}
            autoWidth={true}
            label="Sport"
          >
            {Object.values(Sport).map((sport) => (
              <MenuItem value={sport} key={sport}>
                {sport}
              </MenuItem>
            ))}
          </Select>
          <div>Sport (Optional)</div>
          <div
            onClick={() => setSport("")}
            style={{
              padding: 8,
              backgroundColor: "lightblue",
              cursor: "pointer",
            }}
          >
            Clear
          </div>
        </div>
        <div
          style={{
            marginBottom: 12,
            display: "flex",
            gap: 12,
            alignItems: "center",
          }}
        >
          <Select
            multiple={false}
            value={tokenRewardMultiplier}
            onChange={(e) => setTokenRewardMultiplier(e.target.value as number)}
            autoWidth={true}
            label="Token Reward Multiplier"
          >
            <MenuItem value={2} key={2}>
              2x
            </MenuItem>
            <MenuItem value={3} key={3}>
              3x
            </MenuItem>
            <MenuItem value={4} key={4}>
              4x
            </MenuItem>
            <MenuItem value={5} key={5}>
              5x
            </MenuItem>
          </Select>
          <div>Token Reward Multiplier (Optional)</div>
          <div
            onClick={() => setTokenRewardMultiplier(undefined)}
            style={{
              padding: 8,
              backgroundColor: "lightblue",
              cursor: "pointer",
            }}
          >
            Clear
          </div>
        </div>
        <div className={style.checkboxRow}>
          <Checkbox
            checked={format1v1}
            onChange={(e) => setFormat1v1(e.target.checked)}
          />
          <div>1v1 Contests</div>
        </div>{" "}
        <div className={style.checkboxRow}>
          <Checkbox
            checked={formatGroup}
            onChange={(e) => setFormatGroup(e.target.checked)}
          />
          <div>Group Contests</div>
        </div>
        <div className={style.checkboxRow}>
          <Checkbox
            checked={freeEntry}
            onChange={(e) => setFreeEntry(e.target.checked)}
          />
          <div>Free Entry</div>
        </div>{" "}
        <div className={style.checkboxRow}>
          <Checkbox
            checked={tokenEntry}
            onChange={(e) => setTokenEntry(e.target.checked)}
          />
          <div>Token Entry</div>
        </div>
        {tokenEntry && (
          <>
            <Components.TextInput
              className={style.textInput}
              value={minTokenEntry}
              onChange={setMinTokenEntry}
              label="Token Entry"
              inputType="number"
              error={minTokenEntryError}
            />
            {/* <Components.TextInput
              className={style.textInput}
              value={maxTokenEntry}
              onChange={setMaxTokenEntry}
              label="Max Token Entry"
              inputType="number"
              error={maxTokenEntryError}
            /> */}
            <div
              style={{
                marginBottom: 12,
                display: "flex",
                gap: 12,
                alignItems: "center",
              }}
            >
              <Select
                multiple={false}
                value={rewardId}
                onChange={(e) => setRewardId(e.target.value as string)}
                autoWidth={true}
                label="Reward"
              >
                {rewardsArray.map((reward) => (
                  <MenuItem value={reward.id} key={reward.id}>
                    {reward.name}
                  </MenuItem>
                ))}
              </Select>
              <div>Reward</div>
            </div>
          </>
        )}
      </div>
      <Components.Button
        className={style.submit}
        label={"Submit"}
        onClick={onSubmit}
        inProgress={inProgress}
      />
      {submitError && <div className={style.error}>{submitError}</div>}
    </div>
  );
};

export const UpdateTemplate = connect(
  (state: RootState, ownProps: ComponentProps) => ({
    rewards: state.rewards.rewards.items,
    brands: state.rewards.brands.items,
    contestTemplate: !!state.contests.contestTemplates
      ? state.contests.contestTemplates[ownProps.contestTemplateId]
      : ({} as Models.Contests.ContestTemplate),
  }),
)(UpdateTemplateInternal);
