import React from "react";
import { useApolloClient, useMutation } from "@apollo/client";
import { Mutation, Query } from "../../../gql";
import { Icon } from "../../../components";
import * as style from "./ReResultMatchups.style";
import * as Models from "../../../models";
import { toast } from "react-toastify";
import { MatchupStatus } from "../../../models/contest";
import * as Components from "../../../components";
import { toShortDateAndTimeUTC } from "../../../utility/date";

export const ReResultMatchups: React.FC = () => {
  const client = useApolloClient();
  const [matchupsArr, setMatchupsArr] = React.useState<
    Models.Contests.Matchup[] | null
  >(null);
  const [selectedMatchup, setSelectedMatchup] =
    React.useState<Models.Contests.Matchup | null>(null);
  const [inProgress, setInProgress] = React.useState<boolean>(false);
  const [isVoidSelected, setIsVoidSelected] = React.useState<boolean>(false);
  const [selectedMatchupOptionId, setSelectedMatchupOptionId] = React.useState<
    number | null
  >(null);
  const [canLoadMore, setCanLoadMore] = React.useState(true);

  const selectedMatchupOption = React.useMemo(() => {
    if (!!selectedMatchup && !!selectedMatchupOptionId) {
      return selectedMatchup.matchupOptions.find(
        (opt) => opt.matchupOption.id === selectedMatchupOptionId,
      );
    }
  }, [selectedMatchup, selectedMatchupOptionId]);

  const getMatchups = (skip?: number) => {
    client
      .query({
        query: Query.GET_MATCHUPS_QUERY,
        variables: {
          take: 20,
          skip: skip ?? 0,
          eventStartTimeLessThan: new Date(),
          status: MatchupStatus.SETTLED,
          reverseOrder: true,
        },
      })
      .then((res) => {
        if (res?.data?.adminMatchups) {
          if (res.data.adminMatchups.length) {
            setMatchupsArr(
              !!skip && !!matchupsArr
                ? [...matchupsArr, ...res.data.adminMatchups]
                : res.data.adminMatchups,
            );
          } else if (!!skip) {
            setCanLoadMore(false);
          } else {
            setMatchupsArr([]);
          }
        }
      })
      .catch((res) => {
        toast.error("Issue getting Matchups");
      });
  };

  React.useEffect(() => {
    getMatchups();
  }, [selectedMatchup]);

  const [reresultMatchupMutation, resultMatchupMutationStatus] = useMutation(
    Mutation.RERESULT_MATCHUP_MUTATION,
  );
  const resultMatchup = (matchupId: number, matchupOptionId: number) => {
    setInProgress(true);
    reresultMatchupMutation({
      variables: {
        matchupId,
        matchupOptionId,
      },
    })
      .then((res) => {
        if (res?.data?.adminReResultMatchup) {
          setInProgress(false);
          setSelectedMatchup(null);
          setSelectedMatchupOptionId(null);
          toast.success("Success resulting matchup");
        }
      })
      .catch((e) => {
        setInProgress(false);
        setSelectedMatchup(null);
        setSelectedMatchupOptionId(null);
        toast.error("Could not result matchup");
      });
  };

  const [voidMatchupMutation, voidMatchupMutationStatus] = useMutation(
    Mutation.VOID_MATCHUP_MUTATION,
  );
  const voidMatchup = (matchupId: number) => {
    setInProgress(true);
    voidMatchupMutation({
      variables: {
        matchupId,
      },
    })
      .then((res) => {
        if (res?.data?.adminVoidMatchup) {
          setInProgress(false);
          setSelectedMatchup(null);
          setSelectedMatchupOptionId(null);
          toast.success("Success resettling matchup");
        }
      })
      .catch((e) => {
        setInProgress(false);
        setSelectedMatchup(null);
        setSelectedMatchupOptionId(null);
        toast.error("Could not resettle matchup");
      });
  };

  return (
    <div>
      {!!matchupsArr ? (
        <>
          <div className={style.table}>
            <div className={style.row}>
              <div className={style.rowItemBold}>Name</div>
              <div className={style.rowItemBold}>Event Start Time</div>
              <div className={style.rowItemBold}>Current Result</div>
              <div className={style.rowItemBold}>Resettle Matchup</div>
            </div>
            {matchupsArr.map((matchup: Models.Contests.Matchup) => {
              return (
                <div className={style.row} key={matchup.id}>
                  <div className={style.rowItem}>{matchup.name}</div>
                  <div className={style.rowItem}>
                    {toShortDateAndTimeUTC(new Date(matchup.eventStartTime))}
                  </div>
                  <div className={style.rowItem}>
                    {
                      matchup.matchupOptions.find(
                        (mo) => mo.matchupOption.id === matchup.resultId,
                      )?.matchupOption.name
                    }
                  </div>
                  <div className={style.rowItem}>
                    <Components.Button
                      label="Resettle"
                      onClick={() => {
                        setSelectedMatchup(matchup);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          {canLoadMore && (
            <Components.Button
              onClick={() => getMatchups(matchupsArr.length)}
              className={style.buttonInTable}
              label="Load More Matchups"
            />
          )}
        </>
      ) : (
        <Icon.Spinner size={24} />
      )}

      {!!selectedMatchup && (
        <>
          <h1 style={{ textAlign: "center" }}>Matchup</h1>
          <div className={style.table}>
            <div className={style.row}>
              <div className={style.rowItemBold}>{selectedMatchup.name}</div>
              <div className={style.rowItemBold}>
                {toShortDateAndTimeUTC(
                  new Date(selectedMatchup.eventStartTime),
                )}
              </div>
              <div className={style.rowItemBold}>
                {
                  selectedMatchup.matchupOptions.find(
                    (opt) => opt.matchupOption.id === selectedMatchup.resultId,
                  )?.matchupOption.name
                }
              </div>
              <div className={style.rowItem}>
                {selectedMatchup.matchupOptions[0].matchupOption.id !==
                  selectedMatchup.resultId && (
                  <Components.Button
                    label={selectedMatchup.matchupOptions[0].matchupOption.name}
                    className={style.buttonInTable}
                    onClick={() => {
                      setSelectedMatchupOptionId(
                        selectedMatchup.matchupOptions[0].matchupOption.id,
                      );
                    }}
                  />
                )}
                {selectedMatchup.matchupOptions[1].matchupOption.id !==
                  selectedMatchup.resultId && (
                  <Components.Button
                    label={selectedMatchup.matchupOptions[1].matchupOption.name}
                    className={style.buttonInTable}
                    onClick={() => {
                      setSelectedMatchupOptionId(
                        selectedMatchup.matchupOptions[1].matchupOption.id,
                      );
                    }}
                  />
                )}
                <Components.Button
                  label="Void"
                  className={style.buttonInTable}
                  onClick={() => {
                    setIsVoidSelected(true);
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}

      {(!!isVoidSelected || !!selectedMatchupOptionId) && selectedMatchup && (
        <div className={style.modalContainer}>
          <div
            style={{ fontSize: "20px", fontWeight: "bold" }}
            className={style.modalContainerItems}
          >
            {selectedMatchup.name}
          </div>
          <div className={style.modalContainerItems}>
            Description: {selectedMatchup.description}
          </div>
          <div>
            {" "}
            Event Start Time:{" "}
            {toShortDateAndTimeUTC(new Date(selectedMatchup.eventStartTime))}
          </div>
          <div className={style.modalContainerItems}>
            Are you sure you want to{" "}
            {isVoidSelected
              ? `Resettle ${selectedMatchup.name} matchup as void?`
              : `Rsettle with matchup option ${selectedMatchupOption?.matchupOption.name}?`}
          </div>
          <Components.Button
            className={style.modalContainerItems}
            onClick={() => {
              if (isVoidSelected) {
                voidMatchup(selectedMatchup.id);
              } else if (!!selectedMatchupOptionId) {
                resultMatchup(selectedMatchup.id, selectedMatchupOptionId);
              }
            }}
            label="Confirm"
          />
          <Components.Button
            label="Cancel"
            className={style.redButton}
            onClick={() => {
              setIsVoidSelected(false);
              setSelectedMatchupOptionId(null);
            }}
          />
        </div>
      )}
    </div>
  );
};
