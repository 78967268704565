import * as Styles from "../../../styles";
import { style as s } from "typestyle";

export const component = s({});

export const table = s({
  border: "1px solid black",
});

export const selectedRow = s({
  border: "1px solid yellow",
});

export const row = s({
  display: "flex",
  $nest: {
    "&:nth-child(even)": {
      backgroundColor: Styles.colors.darkTranslucent100,
    },
  },
});

export const rowItem = s({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flex: 1,
  padding: 4,
});

export const imageContainer = s({
  height: "40px",
});

export const image = s({
  height: "100%",
});

export const subTitle = s({
  textAlign: "center",
  fontSize: 32,
  fontWeight: 500,
});

export const successText = s({
  color: Styles.colors.green,
});

export const errorText = s({
  color: Styles.colors.error,
});

export const topNav = s({
  width: "100%",
  border: `1px solid black`,
  display: "flex",
  marginBottom: 16,
  marginTop: 32,
});

export const topNavItem = s({
  flex: 1,
  textAlign: "center",
  padding: 12,
  borderRight: "1px solid black",
  $nest: {
    "&:last-child": {
      borderRightWidth: 0,
    },
  },
});

export const activeTopNavItem = s({
  backgroundColor: Styles.colors.softGreen,
});
