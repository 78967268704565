import { gql } from "@apollo/client";

export const GET_QUESTS_QUERY = gql`
  query adminGetQuests($skip: Int!, $take: Int!) {
    adminGetQuests(skip: $skip, take: $take) {
      id
      createdAtUtc
      updatedAtUtc
      adminUpdatedBy
      name
      description
      questType
      tier
      requiredActionCount
      rewardAmount
      status
    }
  }
`;
