import { gql } from "@apollo/client";

export const UPDATE_BRAND_MUTATION = gql`
  mutation adminUpdateBrand($id: Int!, $imageInB64: String!) {
    adminUpdateBrand(id: $id, imageInB64: $imageInB64) {
      id
      name
      imageUrl
    }
  }
`;
