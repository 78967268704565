import React from "react";
import * as style from "./EditMatchupOption.style";
import { useApolloClient, useMutation } from "@apollo/client";
import { HexAlphaColorPicker, HexColorInput } from "react-colorful";
import { Mutation, Query } from "../../../gql";
import { connect, useDispatch } from "react-redux";
import { ContestActions } from "../../../store/actions";
import * as Models from "../../../models";
import { RootState } from "../../../store/reducers";
import * as Components from "../../../components";
import {
  editMatchupOptionReducer,
  editMatchupOptionDefaultState,
} from "./EditMatchupOption.reducer";
import InfiniteScroll from "react-infinite-scroll-component";
import { classes } from "typestyle";

interface StateProps {
  matchupOptions: Record<number, Models.Contests.MatchupOption> | null;
}

export const EditMatchupOptionInternal: React.FC<StateProps> = ({
  matchupOptions,
}) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const [hasMoreMatchupOptions, setHasMoreMatchupOptions] =
    React.useState(true);
  const [optionFilter, setOptionFilter] = React.useState("");
  const [selectedMatchupOption, setSelectedMatchupOption] =
    React.useState<Models.Contests.MatchupOption | null>(null);
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  React.useEffect(() => {
    if (selectedFile != null) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(selectedFile);
      fileReader.onload = () => {
        localDispatch({
          type: "UPDATE_DEFAULT_VISUALIZATION",
          payload: {
            field: "newImageInB64",
            value: fileReader!.result!.toString(),
          },
        });
      };
    }
  }, [selectedFile]);
  /**
   * Values
   */
  const [state, localDispatch] = React.useReducer(
    editMatchupOptionReducer,
    editMatchupOptionDefaultState,
  );

  const matchupOptionsArray = React.useMemo(() => {
    return matchupOptions
      ? Object.values(matchupOptions).filter(
          (opt) =>
            !optionFilter ||
            opt.name.toLocaleLowerCase().includes(optionFilter),
        )
      : [];
  }, [matchupOptions, optionFilter]);

  const getMatchupOptions = (skip: number) => {
    client
      .query({
        query: Query.GET_MATCHUP_OPTIONS_QUERY,
        variables: {
          take: 100,
          skip,
        },
      })
      .then((res) => {
        if (res?.data?.adminMatchupOptions?.length) {
          dispatch(
            ContestActions.getMatchupOptionsSuccess({
              matchupOptions: res.data.adminMatchupOptions,
            }),
          );
        } else {
          setHasMoreMatchupOptions(false);
        }
      })
      .catch((res) => {
        console.log("failed", res);
      });
  };

  React.useEffect(() => {
    getMatchupOptions(0);
  }, []);

  const [
    updateMatchupOptionDefaultVisualizationMutation,
    updateMatchupOptionDefaultVisualizationStatus,
  ] = useMutation(Mutation.ADMIN_UPDATE_MATCHUP_OPTION_DEFAULT_VIZUALIZATION);
  React.useEffect(() => {
    if (!!state.readyToSubmit) {
      updateMatchupOptionDefaultVisualizationMutation({
        variables: {
          matchupOptionId: selectedMatchupOption?.id,
          newVisualization: {
            description: state.description,
            displayName: state.displayName,
            initials: state.initials,
            initialsBackgroundColor: state.initialsBackgroundColor,
            initialsTextColor: state.initialsTextColor,
            newImageInB64: state.newImageInB64,
          },
        },
      })
        .then((res) => {
          if (res.data.adminUpdateMatchupOptionDefaultVizualization) {
            localDispatch({ type: "SUBMIT_SUCCESS" });
            dispatch(
              ContestActions.updateMatchupOptionDefaultVisualizationSuccess(
                res.data.adminUpdateMatchupOptionDefaultVizualization,
              ),
            );
            setSelectedFile(null);
            setSelectedMatchupOption(null);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [state.readyToSubmit]);

  return (
    <div className={style.main}>
      <div className={style.title} style={{ fontSize: 32 }}>
        Edit Matchup Option
      </div>
      <div className={style.subtitle}>Start by selecting a Matchup Option</div>
      {state.errors["matchupOptions"] && (
        <div className={style.error} style={{ textAlign: "center" }}>
          {state.errors["matchupOptions"]}
        </div>
      )}
      <div className={style.matchupOptionsWrapper}>
        <div className={style.matchupOptionsSection}>
          <Components.TextInput
            className={style.textInput}
            value={optionFilter}
            onChange={(e) => setOptionFilter(e.toLocaleLowerCase())}
            label="Filter"
          />
          <div className={style.matchupOptionSelectionWrapper}>
            <InfiniteScroll
              dataLength={matchupOptionsArray.length} //This is important field to render the next data
              next={() => getMatchupOptions(matchupOptionsArray.length)}
              hasMore={hasMoreMatchupOptions}
              loader={<Components.Icon.Spinner size={30} />}
            >
              {matchupOptionsArray.map((opt) => (
                <div className={style.matchupOptionWrapper} key={opt.id}>
                  <div
                    className={classes(
                      style.matchupOption,
                      selectedMatchupOption?.id === opt.id
                        ? style.selectedMatchupOption
                        : "",
                    )}
                    onClick={() => setSelectedMatchupOption(opt)}
                  >
                    {opt.name}
                  </div>
                </div>
              ))}
            </InfiniteScroll>
          </div>
          <Components.Button
            label="Fetch More"
            onClick={() => getMatchupOptions(matchupOptionsArray.length)}
            disabled={!hasMoreMatchupOptions}
          />
        </div>
      </div>

      {!!selectedMatchupOption && (
        <>
          <div className={style.subtitle}>Update Default Visualization</div>
          {selectedMatchupOption.defaultMatchupOptionVisualization.initials ? (
            <div className={style.defaultMatchupOptionVisualization}>
              <div
                className={style.initialsContainer}
                style={{
                  backgroundColor:
                    selectedMatchupOption.defaultMatchupOptionVisualization
                      .initialsBackgroundColor,
                  color:
                    selectedMatchupOption.defaultMatchupOptionVisualization
                      .initialsTextColor,
                }}
              >
                <div className={style.initials}>
                  {
                    selectedMatchupOption.defaultMatchupOptionVisualization
                      .initials
                  }
                </div>
              </div>
            </div>
          ) : null}
          <div className={style.description}>
            None of these fields are required. If all are left empty the Matchup
            Option "Name" will be used. If included, image is first priority,
            then initials (background and text colors are required), then
            display name.
          </div>
          <Components.TextInput
            className={style.textInput}
            value={state.displayName}
            onChange={(e) =>
              localDispatch({
                type: "UPDATE_DEFAULT_VISUALIZATION",
                payload: {
                  field: "displayName",
                  value: e,
                },
              })
            }
            label="Display Name"
            error={state.errors["displayName"]}
            autocomplete="off"
          />
          <Components.TextInput
            className={style.textInput}
            value={state.description}
            onChange={(e) =>
              localDispatch({
                type: "UPDATE_DEFAULT_VISUALIZATION",
                payload: {
                  field: "description",
                  value: e,
                },
              })
            }
            label="Description"
            error={state.errors["description"]}
            autocomplete="off"
          />
          <Components.TextInput
            className={style.textInput}
            value={state.initials}
            onChange={(e) =>
              localDispatch({
                type: "UPDATE_DEFAULT_VISUALIZATION",
                payload: {
                  field: "initials",
                  value: e,
                },
              })
            }
            label="Initials"
            error={state.errors["initials"]}
            autocomplete="off"
          />
          <div className={style.colorRow}>
            <HexAlphaColorPicker
              className={style.colorBox}
              color={state.initialsBackgroundColor}
              onChange={(e) =>
                localDispatch({
                  type: "UPDATE_DEFAULT_VISUALIZATION",
                  payload: {
                    field: "initialsBackgroundColor",
                    value: e,
                  },
                })
              }
              prefix="#"
            />
            <HexColorInput
              color={state.initialsBackgroundColor}
              onChange={(e) =>
                localDispatch({
                  type: "UPDATE_DEFAULT_VISUALIZATION",
                  payload: {
                    field: "initialsBackgroundColor",
                    value: e,
                  },
                })
              }
              alpha
            />
            <div className={style.label}>Initials Background Color</div>
            {state.errors["initialsBackgroundColor"] && (
              <div className={style.error}>
                {state.errors["initialsBackgroundColor"]}
              </div>
            )}
          </div>
          <div className={style.colorRow}>
            <HexAlphaColorPicker
              className={style.colorBox}
              color={state.initialsTextColor}
              onChange={(e) =>
                localDispatch({
                  type: "UPDATE_DEFAULT_VISUALIZATION",
                  payload: {
                    field: "initialsTextColor",
                    value: e,
                  },
                })
              }
              prefix="#"
            />
            <HexColorInput
              color={state.initialsTextColor}
              onChange={(e) =>
                localDispatch({
                  type: "UPDATE_DEFAULT_VISUALIZATION",
                  payload: {
                    field: "initialsTextColor",
                    value: e,
                  },
                })
              }
              alpha
            />
            <div className={style.label}>Initials Text Color</div>

            {state.errors["initialsTextColor"] && (
              <div className={style.error}>
                {state.errors["initialsTextColor"]}
              </div>
            )}
          </div>
          <div className={style.input}>
            <input
              type="file"
              name="image"
              onChange={(e) => setSelectedFile(e!.currentTarget!.files![0])}
              accept="image/jpg, image/jpeg, image/png, image/svg, image/gif"
            />
            {state.errors["newImageInB64"] && (
              <div className={style.error}>{state.errors["newImageInB64"]}</div>
            )}
          </div>
          <div className={style.imageContainer}>
            <img
              src={state.newImageInB64}
              alt="prop image"
              className={style.image}
            />
          </div>
          <div
            onClick={() => {
              setSelectedFile(null);
              localDispatch({
                type: "UPDATE_DEFAULT_VISUALIZATION",
                payload: {
                  field: "newImageInB64",
                  value: "",
                },
              });
            }}
            className={style.removeImageButton}
          >
            Remove image
          </div>
          <Components.Button
            className={style.button}
            onClick={() => localDispatch({ type: "SUBMIT" })}
            label="Submit"
            inProgress={state.readyToSubmit}
          />
        </>
      )}
      {state.errors["submit"] && (
        <div className={style.error}>{state.errors["submit"]}</div>
      )}
    </div>
  );
};

export const EditMatchupOption = connect((state: RootState) => ({
  matchupOptions: state.contests.matchupOptions,
}))(EditMatchupOptionInternal);
