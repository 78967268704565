import { gql } from "@apollo/client";

export const CREATE_CONTEST_TEMPLATE_MUTATION = gql`
  mutation adminCreateContestTemplate(
    $name: String!
    $description: String!
    $viewableStartsAtUtc: DateTime!
    $availableStartsAtUtc: DateTime!
    $availableEndsAtUtc: DateTime!
    $minContestants: Int!
    $maxContestants: Int!
    $maxContests: Int
    $tokenEntry: Boolean!
    $freeEntry: Boolean!
    $minTokenEntry: Int
    $maxTokenEntry: Int
    $tokenRewardMultiplier: Int
    $rewardId: Int
    $sponsorId: Int
    $buckets: [CreateBucketArgs!]!
  ) {
    adminCreateContestTemplate(
      name: $name
      description: $description
      viewableStartsAtUtc: $viewableStartsAtUtc
      availableStartsAtUtc: $availableStartsAtUtc
      availableEndsAtUtc: $availableEndsAtUtc
      minContestants: $minContestants
      maxContestants: $maxContestants
      maxContests: $maxContests
      tokenEntry: $tokenEntry
      freeEntry: $freeEntry
      minTokenEntry: $minTokenEntry
      maxTokenEntry: $maxTokenEntry
      tokenRewardMultiplier: $tokenRewardMultiplier
      buckets: $buckets
      rewardId: $rewardId
      sponsorId: $sponsorId
    ) {
      name
    }
  }
`;
