import React from "react";
import * as style from "./UpdateBrand.style";
import * as Components from "../../../../components";
import * as Models from "../../../../models";
import { useMutation } from "@apollo/client";
import { Mutation } from "../../../../gql";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { RewardActions } from "../../../../store/actions";
import { RewardsStoreBrand } from "../../../../store/reducers/rewards";

interface ComponentProps {
  brand: RewardsStoreBrand;
}

export const UpdateBrand: React.FC<ComponentProps> = ({ brand }) => {
  const [imageInB64, setImageInB64] = React.useState<string>("");
  const [inProgress, setInProgress] = React.useState(false);
  const [submitError, setSubmitError] = React.useState<string>("");

  const [updateBrandMutation, updateBrandMutationData] = useMutation(
    Mutation.UPDATE_BRAND_MUTATION,
  );

  const dispatch = useDispatch();

  const onSubmit = () => {
    if (!imageInB64) {
      setSubmitError("Please include an image");
    } else {
      setInProgress(true);
      setSubmitError("");
      updateBrandMutation({
        variables: {
          id: brand.id,
          imageInB64,
        },
      })
        .then((res) => {
          console.log(res);
          setInProgress(false);
          setImageInB64("");
          setSelectedFile(null);
          toast.success("Brand Updated");
          dispatch(RewardActions.UpdateBrandSuccess(res.data.adminUpdateBrand));
        })
        .catch((e) => {
          setInProgress(false);
          console.log(e);
        });
    }
  };

  /** Image  */
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  React.useEffect(() => {
    if (selectedFile != null) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(selectedFile);
      fileReader.onload = () => {
        setImageInB64(fileReader!.result!.toString());
      };
    }
  }, [selectedFile]);
  return (
    <div className={style.component}>
      <Components.TextInput
        className={style.textInput}
        value={brand.name}
        disabled={true}
        label="Name"
        autocomplete="off"
      />
      <div className={style.textInput}>
        <input
          type="file"
          name="image"
          onChange={(e) => setSelectedFile(e!.currentTarget!.files![0])}
          accept="image/jpg, image/jpeg, image/png, image/svg, image/gif"
        />
      </div>
      <div className={style.imageContainer}>
        <img src={imageInB64} alt="prop image" className={style.image} />
      </div>
      <div
        onClick={() => {
          setSelectedFile(null);
          setImageInB64("");
        }}
        className={style.removeImageButton}
      >
        Remove image
      </div>
      <Components.Button
        className={style.button}
        onClick={onSubmit}
        label="Submit"
        inProgress={inProgress}
      />
      <div className={style.error}>{submitError}</div>
    </div>
  );
};
