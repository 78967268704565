import React from "react";
import * as style from "../CreateQuests/CreateQuests.style";
import * as Components from "../../../components";
import * as Models from "../../../models";
import { useMutation } from "@apollo/client";
import { Mutation } from "../../../gql";
import { toast } from "react-toastify";
import { MenuItem, Select } from "@mui/material";

interface ComponentProps {
  quest: Models.Promo.Quest;
  onComplete: (updatedQuest: Models.Promo.Quest) => void;
}

export const UpdateQuest: React.FC<ComponentProps> = ({
  quest,
  onComplete,
}) => {
  const [name, setName] = React.useState<string>(quest.name);
  const [description, setDescription] = React.useState<string>(
    quest.description ?? "",
  );
  const [requiredActionCount, setRequiredActionCount] = React.useState<string>(
    quest.requiredActionCount.toString(),
  );
  const [rewardAmount, setRewardAmount] = React.useState<string>(
    quest.rewardAmount.toString(),
  );
  const [status, setStatus] = React.useState<Models.Promo.QuestStatus>(
    quest.status,
  );

  const [inProgress, setInProgress] = React.useState(false);
  const [submitError, setSubmitError] = React.useState<string>("");

  const [updateQuestMutation, updateQuestMutationStatus] = useMutation(
    Mutation.UPDATE_QUEST_MUTATION,
  );

  const onSubmit = () => {
    let canSubmit = true;
    if (!name || !requiredActionCount || !rewardAmount) {
      setSubmitError(
        "Please include a name, required action amount, and reward amount",
      );
      canSubmit = false;
    }

    let requiredActionCountInt = 0;
    let rewardAmountInt = 0;
    try {
      requiredActionCountInt = Number.parseInt(requiredActionCount);
      rewardAmountInt = Number.parseInt(rewardAmount);
    } catch {
      setSubmitError(
        "Ensure required action amount and reward anmount are valid integers",
      );
      canSubmit = false;
    }
    if (requiredActionCountInt <= 0 || rewardAmountInt <= 0) {
      setSubmitError(
        "Ensure  required action amount and reward values are all greater than 0",
      );
      canSubmit = false;
    }

    if (canSubmit) {
      setInProgress(true);
      setSubmitError("");
      updateQuestMutation({
        variables: {
          id: quest.id,
          name,
          description,
          requiredActionCount: requiredActionCountInt,
          rewardAmount: rewardAmountInt,
          status,
        },
      })
        .then((res) => {
          if (res.data.adminUpdateQuest) {
            setInProgress(false);
            onComplete(res.data.adminUpdateQuest);
          } else {
            toast.error(`Unable to update quest: ${res.errors?.toString()}`);
          }
        })
        .catch((e) => {
          setInProgress(false);
          toast.error(`Unable to update quest: ${e.message}`);
          console.log(e);
        });
    }
  };
  return (
    <div className={style.component}>
      <Components.TextInput
        className={style.textInput}
        value={name}
        onChange={setName}
        label="Title"
        autocomplete="off"
      />
      <Components.TextInput
        className={style.textInput}
        value={description}
        onChange={setDescription}
        label="description"
        autocomplete="off"
      />
      <div className={style.input}>
        <Select
          multiple={false}
          value={status}
          onChange={(e) =>
            setStatus(e.target.value as Models.Promo.QuestStatus)
          }
          autoWidth={true}
          label="Quest Status"
        >
          {Object.values(Models.Promo.QuestStatus).map((target) => (
            <MenuItem value={target} key={target}>
              {target}
            </MenuItem>
          ))}
        </Select>
        <div>Quest Status</div>
      </div>
      <div className={style.input}>
        <Select
          multiple={false}
          value={quest.questType}
          autoWidth={true}
          label="Quest Type"
          disabled={true}
        >
          {Object.values(Models.Promo.QuestType).map((target) => (
            <MenuItem value={target} key={target}>
              {target}
            </MenuItem>
          ))}
        </Select>
        <div>Quest Type</div>
      </div>
      <Components.TextInput
        className={style.textInput}
        value={quest.tier.toString()}
        label="Quest Tier"
        inputType="number"
        disabled={true}
      />
      <Components.TextInput
        className={style.textInput}
        value={requiredActionCount}
        onChange={setRequiredActionCount}
        label="Required Action Amount"
        inputType="number"
      />
      <Components.TextInput
        className={style.textInput}
        value={rewardAmount}
        onChange={setRewardAmount}
        label="Reward Amount"
        inputType="number"
      />
      <Components.Button
        className={style.button}
        onClick={onSubmit}
        label="Submit"
        inProgress={inProgress}
      />
      <div className={style.error}>{submitError}</div>
    </div>
  );
};
