import { gql } from "@apollo/client";

export const GET_USER_QUERY = gql`
  query adminGetUser($id: Int!) {
    adminGetUser(id: $id) {
      id
      email
      displayName
      profileImgUrl
      accountStatus
      testingAccount
      userDate {
        termsAcknowledgedUtc
      }
    }
  }
`;
