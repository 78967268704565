export const toShortDateUTC = (date: Date) => {
  const shortTime = new Intl.DateTimeFormat("en", {
    dateStyle: "short",
  });
  return shortTime.format(date);
};
export const toShortDateAndTimeUTC = (date: Date) => {
  const shortTime = new Intl.DateTimeFormat("en", {
    dateStyle: "short",
    timeStyle: "short",
  });
  return shortTime.format(date);
};

export const toLocalDateTime = (date: Date) => {
  return (
    date.toLocaleDateString("en-us") + " " + date.toLocaleTimeString("en-us")
  );
};

export const toShortTimeWithDayEST = (date: Date) => {
  const shortDate = new Intl.DateTimeFormat("en", {
    weekday: "long",
    hour: "numeric",
    minute: "2-digit",
    timeZone: "America/New_York",
  });
  try {
    return shortDate.format(date);
  } catch {
    return "Invalid Date";
  }
};
