import { ShakeUser } from "./user";

export interface Quest {
  id: number;
  createdAtUtc: Date;
  updatedAtUtc: Date;
  adminUpdatedBy: string;
  name: string;
  description?: string;
  questType: QuestType;
  tier: number;
  requiredActionCount: number;
  rewardType: QuestRewardType;
  rewardAmount: number;
  status: QuestStatus;
}

export interface QuestClaim {
  userId: number;
  user: ShakeUser;
  questId: number;
  quest: Quest;
  createdAtUtc: Date;
  rewardType: QuestRewardType;
  rewardAmount: number;
}

export enum QuestType {
  CREATE_CONTEST_H2H = "CREATE_CONTEST_H2H",
  CREATE_CONTEST_GROUP = "CREATE_CONTEST_GROUP",
  WIN_CONTEST_H2H = "WIN_CONTEST_H2H",
  WIN_CONTEST_GROUP = "WIN_CONTEST_GROUP",
  REFER_A_FRIEND = "REFER_A_FRIEND",
}

export enum QuestRewardType {
  TOKEN = "TOKEN",
}

export enum QuestStatus {
  LIVE = "LIVE",
  DISABLED = "DISABLED",
}
