import { gql } from "@apollo/client";

export const CREATE_MATCHUP_MUTATION = gql`
  mutation adminCreateMatchup(
    $name: String!
    $description: String!
    $eventStartTime: DateTime!
    $internalNote: String
    $imageInB64: String
    $newOptions: [CreateMatchupOptionArgs!]!
    $preexistingOptions: [ConnectPreexisitingMatchupOptionArgs!]!
  ) {
    adminCreateMatchup(
      name: $name
      description: $description
      eventStartTime: $eventStartTime
      internalNote: $internalNote
      imageInB64: $imageInB64
      preexistingOptions: $preexistingOptions
      newOptions: $newOptions
    ) {
      id
      name
      description
      internalNote
      imageUrl
      eventStartTime
      matchupOptions {
        matchupOption {
          id
          name
        }
        matchupOptionVisualization {
          id
          description
          displayName
          initials
          initialsBackgroundColor
          initialsTextColor
          imageUrl
        }
      }
    }
  }
`;
