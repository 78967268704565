import React from "react";
import * as style from "./CreateQuests.style";
import * as Components from "../../../components";
import * as Models from "../../../models";
import { useApolloClient, useMutation } from "@apollo/client";
import { Mutation, Query } from "../../../gql";
import { toast } from "react-toastify";
import { Checkbox, MenuItem, Select } from "@mui/material";

export const CreateQuests = () => {
  const client = useApolloClient();

  const [name, setName] = React.useState<string>("");
  const [description, setDescription] = React.useState<string>("");
  const [tier, setTier] = React.useState<string>("1");
  const [questType, setQuestType] = React.useState<Models.Promo.QuestType>(
    Models.Promo.QuestType.CREATE_CONTEST_H2H,
  );
  const [requiredActionCount, setRequiredActionCount] =
    React.useState<string>("0");
  const [rewardAmount, setRewardAmount] = React.useState<string>("0");

  const [inProgress, setInProgress] = React.useState(false);
  const [submitError, setSubmitError] = React.useState<string>("");

  const [createQuestMutation, createQuestMutationStatus] = useMutation(
    Mutation.CREATE_QUEST_MUTATION,
  );

  const onSubmit = () => {
    let canSubmit = true;
    if (!name || !requiredActionCount || !rewardAmount) {
      setSubmitError(
        "Please include a name, required action amount, and reward amount",
      );
      canSubmit = false;
    }

    let tierInt = 0;
    let requiredActionCountInt = 0;
    let rewardAmountInt = 0;
    try {
      tierInt = Number.parseInt(tier);
      requiredActionCountInt = Number.parseInt(requiredActionCount);
      rewardAmountInt = Number.parseInt(rewardAmount);
    } catch {
      setSubmitError(
        "Ensure tier, required action amount, and reward are valid integers",
      );
      canSubmit = false;
    }
    if (tierInt <= 0 || requiredActionCountInt <= 0 || rewardAmountInt <= 0) {
      setSubmitError(
        "Ensure tier, required action amount, and reward values are all greater than 0",
      );
      canSubmit = false;
    }

    if (canSubmit) {
      setInProgress(true);
      setSubmitError("");
      createQuestMutation({
        variables: {
          name,
          description,
          questType,
          tier: tierInt,
          requiredActionCount: requiredActionCountInt,
          rewardAmount: rewardAmountInt,
        },
      })
        .then((res) => {
          if (res.data.adminCreateQuest) {
            setInProgress(false);
            setName("");
            setDescription("");
            setTier("1");
            setRequiredActionCount("0");
            setRewardAmount("0");
            toast.success("Quest Created!");
          } else {
            toast.error(`Unable to create quest: ${res.errors?.toString()}`);
          }
        })
        .catch((e) => {
          setInProgress(false);
          toast.error(`Unable to create quest: ${e.message}`);
          console.log(e);
        });
    }
  };
  return (
    <div className={style.component}>
      <Components.TextInput
        className={style.textInput}
        value={name}
        onChange={setName}
        label="Title"
        autocomplete="off"
      />
      <Components.TextInput
        className={style.textInput}
        value={description}
        onChange={setDescription}
        label="description"
        autocomplete="off"
      />
      <div className={style.input}>
        <Select
          multiple={false}
          value={questType}
          onChange={(e) =>
            setQuestType(e.target.value as Models.Promo.QuestType)
          }
          autoWidth={true}
          label="Quest Type"
        >
          {Object.values(Models.Promo.QuestType).map((target) => (
            <MenuItem value={target} key={target}>
              {target}
            </MenuItem>
          ))}
        </Select>
        <div>Quest Type</div>
      </div>
      <Components.TextInput
        className={style.textInput}
        value={tier}
        onChange={setTier}
        label="Quest Tier"
        inputType="number"
      />
      <Components.TextInput
        className={style.textInput}
        value={requiredActionCount}
        onChange={setRequiredActionCount}
        label="Required Action Amount"
        inputType="number"
      />
      <Components.TextInput
        className={style.textInput}
        value={rewardAmount}
        onChange={setRewardAmount}
        label="Reward Amount"
        inputType="number"
      />
      <Components.Button
        className={style.button}
        onClick={onSubmit}
        label="Submit"
        inProgress={inProgress}
      />
      <div className={style.error}>{submitError}</div>
    </div>
  );
};
