import React from "react";
import * as style from "./Quests.style";
import { classes } from "typestyle";
import { CreateQuests } from "./CreateQuests";
import { ViewQuests } from "./ViewQuests";

export const Quests = () => {
  const [activeTab, setActiveTab] = React.useState("CreateQuest");
  return (
    <div className={style.component}>
      <div className={style.title}>{`Quests`}</div>
      <div className={style.topNav}>
        <div
          className={classes(
            style.topNavItem,
            activeTab == "CreateQuest" ? style.activeTopNavItem : "",
          )}
          onClick={() => setActiveTab("CreateQuest")}
        >
          Create Quest
        </div>
        <div
          className={classes(
            style.topNavItem,
            activeTab == "ViewQuests" ? style.activeTopNavItem : "",
          )}
          onClick={() => setActiveTab("ViewQuests")}
        >
          View Quests
        </div>
      </div>
      {activeTab == "CreateQuest" && <CreateQuests />}
      {activeTab == "ViewQuests" && <ViewQuests />}
    </div>
  );
};
