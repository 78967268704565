import React from "react";
import * as style from "./ViewBrands.style";
import { useApolloClient } from "@apollo/client";
import { Query } from "../../../gql";
import { RootState } from "../../../store/reducers";
import { connect } from "react-redux";
import * as Models from "../../../models";
import { Icon } from "../../../components";
import { useDispatch } from "react-redux";
import { RewardActions } from "../../../store/actions";
import { RewardsStoreBrand } from "../../../store/reducers/rewards";
import { CreateRewards } from "./CreateRewards";
import { ViewRewards } from "./ViewRewards";
import { classes } from "typestyle";
import { toast } from "react-toastify";
import { UpdateBrand } from "./UpdateBrand";

interface StateProps {
  brands: Record<number, RewardsStoreBrand> | null;
}

export const ViewBrandsInternal: React.FC<StateProps> = ({ brands }) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const [selectedBrand, setSelectedBrand] = React.useState<number | null>(null);
  const [mode, setMode] = React.useState<
    "viewRewards" | "createReward" | "updateBrand"
  >("viewRewards");

  const brandsArray = React.useMemo(() => {
    return !!brands ? Object.values(brands) : [];
  }, [brands]);

  const getBrands = (skip?: number) => {
    client
      .query({
        query: Query.GET_BRANDS_QUERY,
        variables: {
          skip: skip ?? 0,
          take: 50,
        },
      })
      .then((res) => {
        dispatch(RewardActions.GetBrandsSuccess(res.data.adminGetBrands));
      })
      .catch((e) => {
        console.log(e);
        toast.error("Failed to get Brands");
      });
  };
  React.useEffect(() => {
    getBrands();
  }, []);

  return (
    <div className={style.component}>
      {!!brands ? (
        <div className={style.table}>
          {brandsArray.map((brand) => (
            <div
              className={classes(
                style.row,
                brand.id === selectedBrand ? style.selectedRow : "",
              )}
              key={brand.id}
              onClick={() => {
                setMode("viewRewards");
                setSelectedBrand(brand.id);
              }}
            >
              <div className={style.rowItem}>{brand.id}</div>
              <div className={style.rowItem}>{brand.name}</div>
              <div className={style.rowItem}>
                <div className={style.imageContainer}>
                  <img src={brand.imageUrl} className={style.image} />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <Icon.Spinner size={24} />
      )}
      {selectedBrand && (
        <>
          <div className={style.topNav}>
            <div
              className={classes(
                style.topNavItem,
                mode == "createReward" ? style.activeTopNavItem : "",
              )}
              onClick={() => setMode("createReward")}
            >
              Create Reward
            </div>
            <div
              className={classes(
                style.topNavItem,
                mode == "viewRewards" ? style.activeTopNavItem : "",
              )}
              onClick={() => setMode("viewRewards")}
            >
              View Rewards
            </div>
            <div
              className={classes(
                style.topNavItem,
                mode == "updateBrand" ? style.activeTopNavItem : "",
              )}
              onClick={() => setMode("updateBrand")}
            >
              Update Brand
            </div>
          </div>
          {mode === "createReward" ? (
            <CreateRewards brandId={selectedBrand} />
          ) : mode === "viewRewards" ? (
            <ViewRewards
              rewardIds={!!brands ? brands[selectedBrand].rewards : []}
            />
          ) : mode === "updateBrand" && !!brands && !!brands[selectedBrand] ? (
            <UpdateBrand brand={brands[selectedBrand]} />
          ) : null}
        </>
      )}
    </div>
  );
};

export const ViewBrands = connect((state: RootState) => ({
  brands: state.rewards.brands.items,
}))(ViewBrandsInternal);
