import React from "react";
import * as style from "./ViewQuests.style";
import { useApolloClient } from "@apollo/client";
import { Query } from "../../../gql";
import * as Models from "../../../models";
import { Icon } from "../../../components";
import { useDispatch } from "react-redux";
import { classes } from "typestyle";
import { toast } from "react-toastify";
import { UpdateQuest } from "./UpdateQuest";

export const ViewQuests: React.FC = () => {
  const client = useApolloClient();
  const [selectedQuestId, setSelectedQuestId] = React.useState<number | null>(
    null,
  );
  const [quests, setQuests] = React.useState<Models.Promo.Quest[]>([]);

  const getQuests = (skip?: number) => {
    client
      .query({
        query: Query.GET_QUESTS_QUERY,
        variables: {
          skip: skip ?? 0,
          take: 20,
        },
      })
      .then((res) => {
        if (res.data.adminGetQuests) {
          setQuests(res.data.adminGetQuests);
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error("Failed to get Quests");
      });
  };
  React.useEffect(() => {
    getQuests();
  }, []);

  const selectedQuest = React.useMemo(() => {
    return !selectedQuestId
      ? null
      : quests.find((b) => b.id === selectedQuestId);
  }, [selectedQuestId]);

  const onUpdate = (quest: Models.Promo.Quest) => {
    const idx = quests.findIndex((q) => q.id === quest.id);
    const newQuests = [...quests];
    newQuests.splice(idx, 1, quest);
    setQuests(newQuests);
    setSelectedQuestId(null);
  };

  return (
    <div className={style.component}>
      {!!quests ? (
        <div className={style.table}>
          {!!quests.length ? (
            quests.map((quest) => (
              <div
                className={classes(
                  style.row,
                  quest.id === selectedQuestId ? style.selectedRow : "",
                )}
                key={quest.id}
                onClick={() => {
                  setSelectedQuestId(quest.id);
                }}
              >
                <div className={style.rowItem}>{quest.name}</div>
                <div style={{ flex: 1, textAlign: "center" }}>
                  <div>{quest.questType}</div>
                  <div>{`Tier ${quest.tier}`}</div>
                </div>
                <div
                  className={style.rowItem}
                >{`Required: ${quest.requiredActionCount}`}</div>
                <div className={style.rowItem}>
                  {`Earn ${quest.rewardAmount} Tokens`}
                </div>
                <div className={style.rowItem}>{quest.status}</div>
              </div>
            ))
          ) : (
            <div
              className={classes(style.row, style.rowItem)}
              style={{ fontWeight: 600 }}
            >
              No Quests To Show
            </div>
          )}
        </div>
      ) : (
        <Icon.Spinner size={24} />
      )}
      {!!selectedQuest ? (
        <UpdateQuest quest={selectedQuest} onComplete={onUpdate} />
      ) : null}
    </div>
  );
};
