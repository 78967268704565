import { gql } from "@apollo/client";

export const UPDATE_CONTEST_TEMPLATE_MUTATION = gql`
  mutation adminUpdateContestTemplate(
    $id: Int!
    $status: String!
    $name: String!
    $description: String!
    $viewableStartsAtUtc: DateTime!
    $availableStartsAtUtc: DateTime!
    $availableEndsAtUtc: DateTime!
    $minContestants: Int!
    $maxContestants: Int!
    $maxContests: Int
    $tokenEntry: Boolean!
    $freeEntry: Boolean!
    $minTokenEntry: Int
    $maxTokenEntry: Int
    $tokenRewardMultiplier: Int
    $rewardId: Int
    $sponsorId: Int
  ) {
    adminUpdateContestTemplate(
      id: $id
      status: $status
      name: $name
      description: $description
      viewableStartsAtUtc: $viewableStartsAtUtc
      availableStartsAtUtc: $availableStartsAtUtc
      availableEndsAtUtc: $availableEndsAtUtc
      minContestants: $minContestants
      maxContestants: $maxContestants
      maxContests: $maxContests
      tokenEntry: $tokenEntry
      freeEntry: $freeEntry
      minTokenEntry: $minTokenEntry
      maxTokenEntry: $maxTokenEntry
      tokenRewardMultiplier: $tokenRewardMultiplier
      rewardId: $rewardId
      sponsorId: $sponsorId
    ) {
      id
      status
      name
      description
      viewableStartsAtUtc
      availableStartsAtUtc
      availableEndsAtUtc
      minContestants
      maxContestants
      maxContests
      tokenEntry
      freeEntry
      minTokenEntry
      maxTokenEntry
      tokenRewardMultiplier
      rewardId
      sponsorId
      sport
      buckets {
        id
        name
        description
        selectionWindowStartsAtUtc
        selectionWindowEndsAtUtc
        minSelection
        maxSelection
        matchups {
          id
          name
          description
          eventStartTime
          sport
          matchupOptions {
            matchupOptionVisualization {
              id
              description
              displayName
              initials
              initialsBackgroundColor
              initialsTextColor
              imageUrl
            }
            matchupOption {
              id
              name
            }
          }
        }
      }
    }
  }
`;
