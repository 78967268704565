import * as React from "react";
import * as style from "./Header.style";
import {
  SignedIn,
  SignedOut,
  SignInButton,
  UserButton,
  useUser,
  useAuth,
} from "@clerk/clerk-react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../../assets/logo-with-name.png";
import onboardingImage from "../../../assets/Onboarding.png";
import UserIcon from "../../../assets/icons/User.png";
import GridIcon from "../../../assets/icons/Grid.png";
import PlayIcon from "../../../assets/icons/Play.png";
import TrophyIcon from "../../../assets/icons/Trophy.png";
import GiftIcon from "../../../assets/icons/Gift.png";
import DocumentIcon from "../../../assets/icons/Document.png";

export const Header: React.FC = () => {
  const { pathname } = useLocation();

  return (
    <div className={style.header}>
      <div className={style.user}>
        <SignedOut>
          <SignInButton />
        </SignedOut>
        <SignedIn>
          <UserButton />
          <JwtTracker />
        </SignedIn>
      </div>
      <div className={style.logoContainer}>
        <img src={logo} width={140} height={40} />
      </div>
      <Link
        to="/"
        className={`${style.route} ${pathname == "/" ? style.activeRoute : ""}`}
      >
        <img src={GridIcon} className={style.icon} /> Home
      </Link>
      <Link
        to="/users"
        className={`${style.route} ${
          pathname == "/users" ? style.activeRoute : ""
        }`}
      >
        <img src={UserIcon} className={style.icon} /> Users
      </Link>
      <Link
        to="/contests"
        className={`${style.route} ${
          pathname == "/contests" ? style.activeRoute : ""
        }`}
      >
        <img src={PlayIcon} className={style.icon} /> Contests
      </Link>
      <Link
        to="/rewards"
        className={`${style.route} ${
          pathname == "/rewards" ? style.activeRoute : ""
        }`}
      >
        <img src={TrophyIcon} className={style.icon} /> Rewards
      </Link>
      <Link
        to="/prizes"
        className={`${style.route} ${
          pathname == "/prizes" ? style.activeRoute : ""
        }`}
      >
        <img src={GiftIcon} className={style.icon} /> Prizes
      </Link>
      <Link
        to="/bulletins"
        className={`${style.route} ${
          pathname == "/bulletins" ? style.activeRoute : ""
        }`}
      >
        <img src={DocumentIcon} className={style.icon} /> Bulletins
      </Link>
      <Link
        to="/quests"
        className={`${style.route} ${
          pathname == "/quests" ? style.activeRoute : ""
        }`}
      >
        <img src={PlayIcon} className={style.icon} /> Quests
      </Link>
      <Link
        to="/terms"
        className={`${style.route} ${
          pathname == "/terms" ? style.activeRoute : ""
        }`}
      >
        <img src={DocumentIcon} className={style.icon} /> Terms
      </Link>
      <div className={style.onboardingImage}>
        <img src={onboardingImage} width={200} />
      </div>
    </div>
  );
};

export const JwtTracker: React.FC = () => {
  const { getToken } = useAuth();
  const { user } = useUser();
  React.useEffect(() => {
    if (user) {
      getToken({ template: "jwt_with_email" }).then((res) => {
        console.log("JWT Token::\n", res);
        if (res && !!localStorage) {
          localStorage.setItem("userJwt", res);
        }
      });
    }
  }, [user, localStorage]);

  return null;
};
