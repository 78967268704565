import { gql } from "@apollo/client";

export const UPDATE_QUEST_MUTATION = gql`
  mutation adminUpdateQuest(
    $id: Int!
    $name: String
    $description: String
    $requiredActionCount: Int
    $rewardAmount: Int
    $status: QuestStatus
  ) {
    adminUpdateQuest(
      id: $id
      name: $name
      description: $description
      requiredActionCount: $requiredActionCount
      rewardAmount: $rewardAmount
      status: $status
    ) {
      id
      createdAtUtc
      updatedAtUtc
      adminUpdatedBy
      name
      description
      questType
      tier
      requiredActionCount
      rewardAmount
      status
    }
  }
`;
