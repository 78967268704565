import * as React from "react";
import * as style from "./MatchupOptionVisualization.style";
import * as Models from "../../models";
import { NewMatchupOptionVisualization } from "../../pages/ContestTemplates/CreateMatchups/CreateMatchups.reducer";
import { classes } from "typestyle";

interface ComponentProps {
  className?: string;
  moreThan2Options?: boolean;
  matchupOption: Models.Contests.MatchupOption;
  newMatchupOptionVisualization?: NewMatchupOptionVisualization;
  matchupOptionVisualization?: Models.Contests.MatchupOptionVisualization;
}

export const MatchupOptionVisualization: React.FC<ComponentProps> = ({
  className,
  matchupOption,
  matchupOptionVisualization,
  newMatchupOptionVisualization,
  moreThan2Options,
}) => {
  return newMatchupOptionVisualization ? (
    <div className={style.container}>
      {!!moreThan2Options &&
      (newMatchupOptionVisualization.newImageInB64 ||
        newMatchupOptionVisualization.imageUrl) ? (
        <div className={style.imageContainer}>
          <img
            src={
              !!newMatchupOptionVisualization.newImageInB64
                ? newMatchupOptionVisualization.newImageInB64
                : newMatchupOptionVisualization.imageUrl
            }
            className={style.image}
          />
        </div>
      ) : !!moreThan2Options && newMatchupOptionVisualization.initials ? (
        <div
          className={style.initialsContainer}
          style={{
            backgroundColor:
              newMatchupOptionVisualization.initialsBackgroundColor,
            color: newMatchupOptionVisualization.initialsTextColor,
          }}
        >
          <div className={style.initials}>
            {newMatchupOptionVisualization.initials}
          </div>
        </div>
      ) : null}
      <div
        className={classes(style.component, className)}
        style={{ width: moreThan2Options ? "auto" : "fit-content" }}
      >
        {!moreThan2Options &&
        (newMatchupOptionVisualization.newImageInB64 ||
          newMatchupOptionVisualization.imageUrl) ? (
          <div className={style.imageContainer}>
            <img
              src={
                !!newMatchupOptionVisualization.newImageInB64
                  ? newMatchupOptionVisualization.newImageInB64
                  : newMatchupOptionVisualization.imageUrl
              }
              className={style.image}
            />
          </div>
        ) : !moreThan2Options && newMatchupOptionVisualization.initials ? (
          <div
            className={style.initialsContainer}
            style={{
              backgroundColor:
                newMatchupOptionVisualization.initialsBackgroundColor,
              color: newMatchupOptionVisualization.initialsTextColor,
            }}
          >
            <div className={style.initials}>
              {newMatchupOptionVisualization.initials}
            </div>
          </div>
        ) : null}
        <div className={style.label}>
          {!!matchupOptionVisualization?.displayName
            ? matchupOptionVisualization.displayName
            : matchupOption.name}
        </div>
        {newMatchupOptionVisualization.description && (
          <div className={style.description}>
            {newMatchupOptionVisualization.description}
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className={style.container}>
      {!!moreThan2Options && matchupOptionVisualization?.imageUrl ? (
        <div className={style.imageContainer}>
          <img
            src={matchupOptionVisualization.imageUrl}
            className={style.image}
          />
        </div>
      ) : !!moreThan2Options && matchupOptionVisualization?.initials ? (
        <div
          className={style.initialsContainer}
          style={{
            backgroundColor: matchupOptionVisualization.initialsBackgroundColor,
            color: matchupOptionVisualization.initialsTextColor,
          }}
        >
          <div className={style.initials}>
            {matchupOptionVisualization.initials}
          </div>
        </div>
      ) : null}
      <div
        className={classes(style.component, className)}
        style={{ width: moreThan2Options ? "auto" : "fit-content" }}
      >
        {!moreThan2Options && matchupOptionVisualization?.imageUrl ? (
          <div className={style.imageContainer}>
            <img
              src={matchupOptionVisualization.imageUrl}
              className={style.image}
            />
          </div>
        ) : !moreThan2Options && matchupOptionVisualization?.initials ? (
          <div
            className={style.initialsContainer}
            style={{
              backgroundColor:
                matchupOptionVisualization.initialsBackgroundColor,
              color: matchupOptionVisualization.initialsTextColor,
            }}
          >
            <div className={style.initials}>
              {matchupOptionVisualization.initials}
            </div>
          </div>
        ) : null}
        <div className={style.label}>
          {!!matchupOptionVisualization?.displayName
            ? matchupOptionVisualization.displayName
            : matchupOption.name}
        </div>
      </div>
    </div>
  );
};
