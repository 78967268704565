import { gql } from "@apollo/client";

export const GET_USERS_QUERY = gql`
  query adminGetUsers(
    $take: Int!
    $skip: Int!
    $email: String
    $displayName: String
  ) {
    adminGetUsers(
      take: $take
      skip: $skip
      email: $email
      displayName: $displayName
    ) {
      id
      email
      displayName
      profileImgUrl
      accountStatus
      testingAccount
      userDate {
        termsAcknowledgedUtc
      }
    }
  }
`;
