import React from "react";
import * as style from "./Users.style";
import { useApolloClient, useMutation } from "@apollo/client";
import { Mutation, Query } from "../../gql";
import * as Models from "../../models";
import * as Components from "../../components";
import { classes } from "typestyle";
import { Checkbox, MenuItem, Select } from "@mui/material";
import { toast } from "react-toastify";
import { AddTokensModal } from "./AddTokensModal";

export const Users: React.FC = () => {
  const client = useApolloClient();
  const [userInputSearch, setUserInputSearch] = React.useState<string>("");
  const [searchType, setSearchType] = React.useState<SearchType>(SearchType.ID);
  const [users, setUsers] = React.useState<
    Models.Users.ShakeUser[] | undefined
  >(undefined);
  const [selectedUser, setSelectedUser] =
    React.useState<Models.Users.ShakeUser | null>(null);
  const [displayName, setDisplayName] = React.useState("");
  const [accountStatus, setAccountStatus] = React.useState("");
  const [testingAccount, setTestingAccount] = React.useState(false);
  const [removeImage, setRemoveImage] = React.useState(false);

  const [showAddTokens, setShowAddTokens] = React.useState<number | null>(null);
  const [canFetchMoreUsers, setCanFetchMoreUsers] = React.useState(true);

  const getUsers = () => {
    client
      .query({
        query: Query.GET_USERS_QUERY,
        variables: {
          take: 50,
          skip: !userInputSearch && !!users ? users.length : 0,
          email:
            !!userInputSearch && searchType === "email"
              ? userInputSearch
              : undefined,
          displayName:
            !!userInputSearch && searchType === "displayName"
              ? userInputSearch
              : undefined,
          policy: "no-cache",
        },
      })
      .then((res) => {
        if (res?.data?.adminGetUsers?.length) {
          setUsers(res.data.adminGetUsers);
          if (res.data.adminGetUsers.length < 50) {
            setCanFetchMoreUsers(false);
          }
        } else {
          toast.error(
            "Something went wrong. There may not be be any users with that email or displayName",
          );
        }
      })
      .catch((res) => {
        console.log("failed", res);
      });
  };

  React.useEffect(() => {
    getUsers();
  }, []);

  const getUser = () => {
    let searchId: number | null = null;
    try {
      searchId = Number.parseInt(userInputSearch);
    } catch (e) {
      toast.error("Failed parsing userInputSearch string to number");
    }

    if (!searchId) {
      toast.error("must include an id");
    } else {
      client
        .query({
          query: Query.GET_USER_QUERY,
          variables: {
            id: searchId,
            policy: "no-cache",
          },
        })
        .then((res) => {
          if (!!res?.data?.adminGetUser) {
            setUsers([res.data.adminGetUser]);
          } else {
            toast.error(`Could not find a User with that ID`);
          }
        })
        .catch((res) => {
          console.log("failed", res);
        });
    }
  };

  const [updateUserMutation, updateUserMutationData] = useMutation(
    Mutation.UPDATE_USER_MUTATION,
  );
  const onSubmit = () => {
    updateUserMutation({
      variables: {
        userId: selectedUser?.id,
        displayName,
        accountStatus,
        testingAccount,
        removeImage,
      },
    })
      .then((res) => {
        if (res.data.adminUpdateUser) {
          setUserInputSearch(res.data.adminUpdateUser.id);
          setSearchType(SearchType.ID);
          setUsers([res.data.adminUpdateUser]);
          toast.success("Success: Updated User");
        }
      })
      .catch((e) => {
        toast.error(JSON.parse(JSON.stringify(e)).message);
      });
  };

  const selectUser = (user: Models.Users.ShakeUser) => {
    if (selectedUser === null || user.id !== selectedUser.id) {
      setSelectedUser(user);
    } else {
      setSelectedUser(null);
    }
  };

  React.useEffect(() => {
    if (users && selectedUser) {
      setDisplayName(selectedUser.displayName ?? "");
      setAccountStatus(selectedUser.accountStatus ?? "");
      setTestingAccount(selectedUser.testingAccount);
      setRemoveImage(false);
    }
  }, [selectedUser]);

  const resetUsers = () => {
    setUserInputSearch("");
    setSearchType(SearchType.ID);
    setSelectedUser(null);
    setDisplayName("");
    setAccountStatus("");
    setTestingAccount(false);
    setRemoveImage(false);
    setUsers(undefined);
  };

  //getUsers() was not getting called before state reset in resetUsers so was moved to this useEffect
  React.useEffect(() => {
    if (
      !userInputSearch &&
      !selectedUser &&
      !displayName &&
      !accountStatus &&
      !testingAccount &&
      !removeImage &&
      !users
    ) {
      getUsers();
    }
  }, [users]);

  return (
    <div className={style.component}>
      <div className={style.searchContainer}>
        <Select
          multiple={false}
          value={searchType}
          onChange={(e) => setSearchType(e.target.value as SearchType)}
          autoWidth={true}
          className={style.dropdown}
        >
          <MenuItem value={"id"}>id</MenuItem>
          <MenuItem value={"email"}>email</MenuItem>
          <MenuItem value={"displayName"}>displayName</MenuItem>
        </Select>
        <Components.TextInput
          className={style.textInput}
          wrapperClassName={style.wrapperTextInput}
          value={userInputSearch}
          onChange={setUserInputSearch}
          label="Search..."
          autocomplete="off"
        />
        <Components.Button
          label="Search"
          onClick={() => {
            if (searchType !== "id") {
              getUsers();
            } else {
              getUser();
            }
          }}
          disabled={!userInputSearch ? true : false}
          className={style.searchButton}
        />
        <Components.Button
          inverse={true}
          label={"Reset Search"}
          onClick={resetUsers}
          className={style.searchButton}
        />
      </div>
      {!!users ? (
        <>
          <div className={style.table}>
            <div className={style.row} style={{ fontWeight: 600 }}>
              <div className={style.rowItem}>Id</div>
              <div className={style.rowItem} style={{ flex: 2 }}>
                Email
              </div>
              <div className={style.rowItem}>Display Name</div>
              <div className={style.rowItem}>Profile Img</div>
              <div className={style.rowItem}>Status</div>
              <div className={style.rowItem}>Tester</div>
            </div>
            {users.map((user) => (
              <>
                <div
                  className={classes(
                    style.row,
                    !!selectedUser && user.id === selectedUser.id
                      ? style.selectedRow
                      : "",
                  )}
                  key={user.id}
                  onClick={() => {
                    selectUser(user);
                    if (user.displayName) {
                      setDisplayName(user?.displayName);
                    }
                    setAccountStatus(user.accountStatus);
                  }}
                >
                  <div className={style.rowItem}>{user.id}</div>
                  <div className={style.rowItem} style={{ flex: 2 }}>
                    {user.email}
                  </div>
                  <div className={style.rowItem}>{user.displayName}</div>
                  <div className={style.rowItem}>
                    <div className={style.imageContainer}>
                      <img src={user.profileImgUrl} className={style.image} />
                    </div>
                  </div>
                  <div className={style.rowItem}>{user.accountStatus}</div>
                  <div className={style.rowItem}>
                    {user.testingAccount ? "true" : "false"}
                  </div>
                </div>
                {!!selectedUser && selectedUser.id === user.id && (
                  <div style={{ padding: 24 }}>
                    <Components.TextInput
                      className={style.textInput}
                      value={displayName}
                      onChange={setDisplayName}
                      label="Display Name"
                      autocomplete="off"
                    />
                    <div className={style.input}>
                      <Select
                        multiple={false}
                        value={accountStatus}
                        onChange={(e) =>
                          setAccountStatus(e.target.value as string)
                        }
                        autoWidth={true}
                        label="Reward"
                      >
                        {accountStatuses.map((status) => (
                          <MenuItem value={status} key={status}>
                            {status}
                          </MenuItem>
                        ))}
                      </Select>
                      <div>Account Status</div>
                    </div>
                    <div className={style.input}>
                      <Checkbox
                        value={removeImage}
                        onChange={(e) => setRemoveImage(e.target.checked)}
                      />
                      <div>Remove Image</div>
                    </div>
                    <div className={style.input}>
                      <Checkbox
                        value={testingAccount}
                        onChange={(e) => setTestingAccount(e.target.checked)}
                      />
                      <div>Testing Account</div>
                    </div>
                    <Components.Button
                      label="Update User"
                      onClick={onSubmit}
                      disabled={
                        removeImage === false &&
                        testingAccount === selectedUser.testingAccount &&
                        displayName === selectedUser.displayName &&
                        accountStatus === selectedUser.accountStatus
                      }
                    />
                    <Components.Button
                      label={
                        !!showAddTokens ? "Close Add Tokens" : "Open Add Tokens"
                      }
                      onClick={() =>
                        !!showAddTokens
                          ? setShowAddTokens(null)
                          : setShowAddTokens(selectedUser.id)
                      }
                    />
                  </div>
                )}
                {showAddTokens &&
                  !!selectedUser &&
                  selectedUser.id === user.id && (
                    <AddTokensModal
                      user={user}
                      onClose={() => setShowAddTokens(null)}
                    />
                  )}
              </>
            ))}
          </div>
          <Components.Button
            label="Get More Users"
            onClick={getUsers}
            disabled={!canFetchMoreUsers}
          />
        </>
      ) : (
        <Components.Icon.Spinner size={24} />
      )}
    </div>
  );
};

const accountStatuses = ["OPEN", "CLOSED", "LOCKED", "SUSPENDED"];

export enum SearchType {
  ID = "id",
  EMAIL = "email",
  DISPLAY_NAME = "displayName",
}
