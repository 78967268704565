import React from "react";
import * as style from "./CreateBuckets.style";
import { classes, stylesheet } from "typestyle";
import { useApolloClient } from "@apollo/client";
import { Query } from "../../../gql";
import { connect, useDispatch } from "react-redux";
import { ContestActions } from "../../../store/actions";
import * as Models from "../../../models";
import { RootState } from "../../../store/reducers";
import {
  CreateBucketActions,
  CreateBucketsState,
} from "./CreateBuckets.reducer";
import * as Components from "../../../components";
import * as Utility from "../../../utility";
import { current } from "@reduxjs/toolkit";
import { MenuItem, Select } from "@mui/material";

interface ComponentProps {
  state: CreateBucketsState;
  localDispatch: React.Dispatch<CreateBucketActions>;
}

interface StateProps {
  matchups: Record<number, Models.Contests.Matchup> | null;
}

export const CreateBucketsInternal: React.FC<ComponentProps & StateProps> = ({
  matchups,
  state,
  localDispatch,
}) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const [canFetchMore, setCanFetchMore] = React.useState(true);

  const matchupsArray = React.useMemo(() => {
    return matchups
      ? Object.values(matchups).sort(
          (matchupA, matchupB) =>
            new Date(matchupA.eventStartTime).getTime() -
            new Date(matchupB.eventStartTime).getTime(),
        )
      : [];
  }, [matchups]);

  const getMatchups = (skip: number) => {
    client
      .query({
        query: Query.GET_MATCHUPS_QUERY,
        fetchPolicy: "no-cache",
        variables: {
          take: 50,
          skip,
          eventStartTimeGreaterThan: new Date(),
          status: Models.Contests.MatchupStatus.PENDING,
        },
      })
      .then((res) => {
        if (res?.data?.adminMatchups?.length) {
          dispatch(
            ContestActions.getMatchupsSuccess({
              matchups: res.data.adminMatchups,
            }),
          );
        } else if (res.data.adminMatchups && !res.data.adminMatchups.length) {
          setCanFetchMore(false);
        }
      })
      .catch((res) => {
        console.log("failed", res);
      });
  };

  React.useEffect(() => {
    getMatchups(0);
  }, []);

  /**
   * Values
   */

  const bucketsArray = React.useMemo(() => {
    return Object.values(state.buckets);
  }, [state.buckets]);

  React.useEffect(() => {
    localDispatch({ type: "CREATE_NEW_BUCKET" });
  }, []);

  const currentBucket = state.buckets[state.currentIndex];
  React.useEffect(() => {
    if (
      !!state.fetchNewCloseDate &&
      !!currentBucket &&
      !!matchups &&
      !!currentBucket.preexistingMatchupIds.length
    ) {
      let earliest =
        matchups[currentBucket.preexistingMatchupIds[0]].eventStartTime;
      currentBucket.preexistingMatchupIds.forEach((matchupId) => {
        const matchup = matchups[matchupId];
        if (matchup && matchup.eventStartTime < earliest) {
          earliest = matchup.eventStartTime;
        }
      });
      let newBucketCloseTime = new Date(earliest);
      newBucketCloseTime.setMinutes(newBucketCloseTime.getMinutes() - 5);
      localDispatch({
        type: "UPDATE_FIELD",
        payload: {
          bucketId: state.currentIndex,
          field: "selectionWindowEndsAtUtc",
          value: newBucketCloseTime,
        },
      });
    }
  }, [state.fetchNewCloseDate]);

  const sortedMatchupIds = React.useMemo(() => {
    return !!currentBucket &&
      !!currentBucket.preexistingMatchupIds &&
      !!matchups
      ? [...currentBucket.preexistingMatchupIds].sort((a, b) =>
          !matchups[a] || !matchups[b]
            ? 0
            : matchups[a].eventStartTime < matchups[b].eventStartTime
            ? -1
            : matchups[b].eventStartTime < matchups[a].eventStartTime
            ? 1
            : matchups[a].name.includes("@")
            ? -1
            : matchups[b].name.includes("@")
            ? 1
            : 0,
        )
      : [];
  }, [currentBucket]);

  return (
    <div className={style.main}>
      <div className={style.title} style={{ fontSize: 32 }}>
        Create Buckets
      </div>
      <div className={style.topNav}>
        {bucketsArray.map(
          (bucket, i) =>
            !bucket.isDeleted && (
              <div
                className={classes(
                  style.topNavItem,
                  state.currentIndex === i ? style.activeTopNavItem : "",
                )}
                key={i}
                onClick={() => {
                  localDispatch({
                    type: "UPDATE_CURRENT_INDEX",
                    payload: { newIndex: i },
                  });
                }}
              >
                {!!bucket.name ? bucket.name : `Bucket ${i + 1}`}
              </div>
            ),
        )}
      </div>
      {currentBucket && (
        <>
          <div className={style.formWrapper}>
            <div className={style.formSection}>
              <Components.TextInput
                className={style.textInput}
                value={currentBucket.name}
                onChange={(e) =>
                  localDispatch({
                    type: "UPDATE_FIELD",
                    payload: {
                      bucketId: state.currentIndex,
                      field: "name",
                      value: e,
                    },
                  })
                }
                label="Name"
                error={currentBucket.errors["name"]}
                autocomplete="off"
              />
              <Components.TextInput
                className={style.textInput}
                value={currentBucket.description}
                onChange={(e) =>
                  localDispatch({
                    type: "UPDATE_FIELD",
                    payload: {
                      bucketId: state.currentIndex,
                      field: "description",
                      value: e,
                    },
                  })
                }
                label="Description"
                error={currentBucket.errors["description"]}
              />
              <Components.DateTimePicker
                wrapperClassName={style.input}
                className={style.textInput}
                value={currentBucket.selectionWindowStartsAtUtc}
                onChange={(e) =>
                  localDispatch({
                    type: "UPDATE_FIELD",
                    payload: {
                      bucketId: state.currentIndex,
                      field: "selectionWindowStartsAtUtc",
                      value: e,
                    },
                  })
                }
                label="Selection Window Start Time"
                error={currentBucket.errors["selectionWindowStartsAtUtc"]}
              />
              <Components.DateTimePicker
                wrapperClassName={style.input}
                className={style.textInput}
                value={currentBucket.selectionWindowEndsAtUtc}
                onChange={(e) =>
                  localDispatch({
                    type: "UPDATE_FIELD",
                    payload: {
                      bucketId: state.currentIndex,
                      field: "selectionWindowEndsAtUtc",
                      value: e,
                    },
                  })
                }
                label="Selection Window End Time"
                error={currentBucket.errors["selectionWindowEndsAtUtc"]}
              />
              <Components.TextInput
                className={style.textInput}
                value={state.buckets[
                  state.currentIndex
                ].minSelection.toString()}
                onChange={(e) =>
                  localDispatch({
                    type: "UPDATE_FIELD",
                    payload: {
                      bucketId: state.currentIndex,
                      field: "minSelection",
                      value: Number.parseInt(e),
                    },
                  })
                }
                label="Selections Required"
                inputType="number"
                error={currentBucket.errors["minSelection"]}
              />{" "}
              <div
                style={{
                  marginBottom: 12,
                  display: "flex",
                  gap: 12,
                  alignItems: "center",
                }}
              >
                <Select
                  multiple={false}
                  value={currentBucket.pointMultiplier}
                  onChange={(e) =>
                    localDispatch({
                      type: "UPDATE_FIELD",
                      payload: {
                        bucketId: state.currentIndex,
                        field: "pointMultiplier",
                        value: e.target.value as number,
                      },
                    })
                  }
                  autoWidth={true}
                  label="Token Reward Multiplier"
                >
                  <MenuItem value={1} key={1}>
                    1x
                  </MenuItem>
                  <MenuItem value={2} key={2}>
                    2x
                  </MenuItem>
                  <MenuItem value={3} key={3}>
                    3x
                  </MenuItem>
                  <MenuItem value={4} key={4}>
                    4x
                  </MenuItem>
                  <MenuItem value={5} key={5}>
                    5x
                  </MenuItem>
                </Select>
                <div>Bucket Point Multiplier</div>
              </div>
              <Components.TextInput
                className={style.textInput}
                value={currentBucket.sortOrder.toString()}
                onChange={(e) =>
                  localDispatch({
                    type: "UPDATE_FIELD",
                    payload: {
                      bucketId: state.currentIndex,
                      field: "sortOrder",
                      value: Number.parseInt(e),
                    },
                  })
                }
                label="Sort Order"
                inputType="number"
                error={currentBucket.errors["sortOrder"]}
              />
              {/* <Components.TextInput
                className={style.textInput}
                value={state.buckets[
                  state.currentIndex
                ].maxSelection.toString()}
                onChange={(e) =>
                  localDispatch({
                    type: "UPDATE_FIELD",
                    payload: {
                      bucketId: state.currentIndex,
                      field: "maxSelection",
                      value: Number.parseInt(e),
                    },
                  })
                }
                label="Max Selection"
                inputType="number"
                error={currentBucket.errors["maxSelection"]}
              /> */}
            </div>
            <div className={style.formSection}>
              <div className={style.subtitle}>Matchups</div>
              {!!matchups && sortedMatchupIds.length ? (
                <div className={style.matchupPreviews}>
                  {sortedMatchupIds.map((id) => (
                    <div key={id} className={style.matchupPreview}>
                      <div className={style.matchupPreviewName}>
                        {matchups[id].name}
                      </div>
                      <div className={style.matchupPreviewDate}>
                        {Utility.date.toShortDateAndTimeUTC(
                          new Date(matchups[id].eventStartTime),
                        )}
                      </div>
                      <div
                        className={style.matchupPreviewOptions}
                        style={{
                          flexDirection:
                            matchups[id].matchupOptions.length > 2
                              ? "column"
                              : "row",
                        }}
                      >
                        {[...matchups[id].matchupOptions]
                          .sort((a, b) =>
                            !!a.sortOrder &&
                            !!b.sortOrder &&
                            a.sortOrder > b.sortOrder
                              ? 1
                              : -1,
                          )
                          .map((opt) => (
                            <div
                              className={style.matchupPreviewOption}
                              key={opt.matchupOption.id}
                            >
                              <div>{opt.matchupOption.name}</div>
                              <div style={{ fontSize: 12 }}>
                                {opt.matchupOptionVisualization.description}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
              <div className={style.matchupSelectionWrapper}>
                {matchupsArray.map((matchup) => (
                  <div
                    key={matchup.id}
                    className={classes(
                      style.matchup,
                      bucketsArray[
                        state.currentIndex
                      ].preexistingMatchupIds.includes(matchup.id)
                        ? style.selectedMatchup
                        : "",
                    )}
                    onClick={() =>
                      localDispatch({
                        type: "MATCHUP_CLICKED",
                        payload: { matchupId: matchup.id },
                      })
                    }
                  >
                    <div>
                      <div>{`${matchup.name}`}</div>
                      <div
                        className={style.matchupDate}
                      >{`${Utility.date.toShortDateAndTimeUTC(
                        new Date(matchup.eventStartTime),
                      )}`}</div>
                    </div>
                  </div>
                ))}
              </div>
              <Components.Button
                onClick={() => {
                  getMatchups(matchupsArray.length);
                }}
                label="Fetch More"
                disabled={!canFetchMore}
              />
            </div>
          </div>
        </>
      )}
      {
        <Components.Button
          className={style.button}
          label={"Delete Bucket"}
          onClick={() =>
            localDispatch({
              type: "DELETE_BUCKET",
              payload: { bucketIndex: state.currentIndex },
            })
          }
          disabled={bucketsArray.length === 1}
        />
      }
      <Components.Button
        className={style.button}
        label={"Add Bucket"}
        onClick={() => localDispatch({ type: "CREATE_NEW_BUCKET" })}
      />
    </div>
  );
};

export const CreateBuckets = connect((state: RootState) => ({
  matchups: state.contests.matchups,
}))(CreateBucketsInternal);
